import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import Dropdown from 'react-dropdown';
import clsx from 'clsx';
import { ethers } from 'ethers';
import { useSetChain } from '@web3-onboard/react';
import useService from 'hooks/useService';
import Button from 'components/Button';
import InputText from 'components/InputText';
import OnlineContext from 'Bridge/views/Online/context';
import StatusDisplay from 'Bridge/views/Online/components/StatusDisplay';
import {
	IFormFields,
	IWithdrawDataFields,
	initalWithdrawData,
} from '../../form';
import axios from 'axios';
import bridgeContractAbi from 'constants/contract/bridge-abi.json';
import IResponseGetUserBalance from 'constants/interfaces/response-getUserBalance';
import IResponseGetChains from 'constants/interfaces/response-getChains';
import IResponseGetTokens from 'constants/interfaces/response-getTokens';
import IResponseGetDailyLimit from 'constants/interfaces/response-getDailyLimit';
import IChainConfig from 'constants/interfaces/chainConfig';
import { IChainInfo } from 'constants/interfaces/chainConfig';
import ITokenConfig from 'constants/interfaces/tokenConfig';
import EChainType from 'constants/enums/chainType';
import ETokens from 'constants/enums/tokens';
import MTokens from 'constants/maps/tokens';
import { useFormik } from 'formik';
import { validation } from './validation';
import { submitAction as withdrawAction } from '../WithdrawForm/form';
import styles from './styles.module.scss';
import { TransferFormProps } from '../../index';
import EActionType from 'constants/enums/action-type';
import { getAssetBridgeOutFee } from 'utils/substrate';
import ScanQrCode from 'components/ScanQRCode';
import { ReactComponent as QrScanSVG } from 'assets/icons/qr_code_scanner.svg';
import InputSelect from 'components/InputSelect';

type IProps = {
	transfer: TransferFormProps;
	data: IFormFields;
	onChange: Dispatch<SetStateAction<IProps['data']>>;
};

type IChainOption = {
	label: string;
	value: string;
	meta: IChainConfig;
};

type ITokenOption = {
	label: string;
	value: string;
	meta: ITokenConfig;
};

export const withdrawDataRef = { current: initalWithdrawData };

export const resetWithdraw = () =>
	(withdrawDataRef.current = withdrawDataRef.current =
		{
			...initalWithdrawData,
		});
function removeLeadingZeroes(str: string) {
	return str.replace(/^0*(?=\d)/u, '');
}

function WithdrawForm({ transfer, data, onChange }: IProps) {
	const [{ connectedChain }] = useSetChain();
	const { address, web3Provider, rpcUrl } = useContext(OnlineContext);

	const [_, { respErrorHandler }] = useService();

	const handleSubmit = async (values: any) => {
		const toChain = toChainOptions![values.toChain].meta;
		const token = tokenOptions![values.token].meta;
		const contract = await new ethers.Contract(
			token.contractAddress,
			bridgeContractAbi,
			web3Provider.getSigner(),
		);
		onChange({
			actionType: EActionType.withdraw,
			toChainInfo: {
				chainType: toChain.chainType,
				chainId: toChain.chainId,
			},
			address: values.address,
			token,
			amount: values.amount,
			contract,
		});
		withdrawAction({ ...transfer });
	};

	const formik = useFormik({
		initialValues: {
			toChain: undefined,
			toChainType: undefined,
			address: '',
			token: undefined,
			amount: '0',
		},
		onSubmit: (values) => handleSubmit(values),
		validationSchema: validation,
	});

	const [toChainOptions, setToChainOptions] = useState<Array<IChainOption>>();
	const [tokenOptions, setTokenOptions] = useState<Array<ITokenOption>>();
	const [withdrawFee, setWithdrawFee] = useState<string>();

	useEffect(() => {
		const controller = new AbortController();

		(async () => {
			try {
				const result = (
					await axios.get<IResponseGetChains>(
						'/secure/bridge/public/chains',
					)
				).data;

				// #NOTE: Disable tron/shasta for now
				// if (process.env.BUILD_MODE === 'production') {
				// 	const tronIdx = result.findIndex(
				// 		(chain) => chain.chainType === EChainType.tron,
				// 	);
				// 	const tronChains =
				// 		tronIdx >= 0 ? result.splice(tronIdx, 1) : [];
				// }

				// #NOTE: Disable ethereum under staging
				if (
					process.env.BUILD_MODE === 'staging' ||
					process.env.BUILD_MODE === 'production'
				) {
					const ethereumIdx = result.findIndex(
						(chain) => chain.chainType === EChainType.ethereum,
					);
					const ethereumChains =
						ethereumIdx >= 0 ? result.splice(ethereumIdx, 1) : [];
				}

				const aminoxIdx = result.findIndex(
					(chain) => chain.chainType === EChainType.aminox,
				);
				const aminoxChains =
					aminoxIdx >= 0 ? result.splice(aminoxIdx, 1) : [];

				const chainOptions = result.map((chain, index) => ({
					label: chain.name,
					value: `${index}`,
					meta: chain,
				}));
				setToChainOptions(chainOptions);
			} catch (e) {
				respErrorHandler(e);
			}
		})();
		resetWithdraw();

		return () => {
			controller.abort();
		};
	}, []);

	useEffect(() => {
		(async () => {
			if (!formik.values.toChain) return;
			const toChain = toChainOptions![formik.values.toChain].meta;
			try {
				const result = (
					await axios.get<IResponseGetTokens>(
						'/secure/bridge/public/tokens',
					)
				).data;

				const tokens = Object.entries(result)
					.map(([type, list]) => {
						const toChainTokenConfig = list.find(
							(token) =>
								token.chainInfo.chainId === toChain!.chainId &&
								token.chainInfo.chainType ===
									toChain!.chainType,
						);
						if (toChainTokenConfig) {
							return {
								type,
								config: list.find(
									(token) =>
										token.chainInfo.chainId ===
										parseInt(connectedChain!.id),
								),
							};
						} else {
							return {
								type,
								config: undefined,
							};
						}
					})
					// #HACK: Disable withdrawal of ACT for now
					.filter((item) => item.config?.symbol !== ETokens.ACT)
					.filter((item) => item.config !== undefined)
					.map((token, index) => ({
						value: `${index}`,
						label: token.config?.symbol,
						meta: token.config,
					})) as ITokenOption[];
				setTokenOptions(tokens);
			} catch (e) {
				respErrorHandler(e);
			}
			if (toChain.chainType === EChainType.tron) {
				formik.setFieldValue('address', '');
			} else {
				formik.setFieldValue('address', address);
				formik.setTouched({ address: true });
			}
		})();
	}, [formik.values.toChain]);

	useEffect(() => {
		if (!formik.values.token) {
			setWithdrawFee(undefined);
			return;
		}

		const token = tokenOptions![formik.values.token].meta;

		const tokenType = MTokens[token.symbol];
		const decimals = token.decimals;
		const contractAddress = token.contractAddress;

		(async () => {
			getAssetBridgeOutFee(rpcUrl, tokenType, (fee: bigint) => {
				const value = ethers.utils.formatUnits(
					fee.toString(),
					decimals,
				);
				setWithdrawFee(value);
			});

			// try {
			// 	const result = (
			// 		await axios.get<IResponseGetDailyLimit>(
			// 			`/secure/bridge/withdrawalCondition/${tokenType}`,
			// 		)
			// 	).data;
			// 	const dailyAvailableInUnit =
			// 		result.dailyLimit - result.dailyTotal;
			// 	const dailyAvailable = ethers.utils.formatUnits(
			// 		dailyAvailableInUnit.toString(),
			// 		decimals,
			// 	);
			// 	formik.setFieldValue('dailyAvailable', dailyAvailable);
			// } catch (e) {
			// 	respErrorHandler(e);
			// 	console.log(e);
			// }
			const contract = await new ethers.Contract(
				contractAddress,
				bridgeContractAbi,
				web3Provider.getSigner(),
			);
			const balanceInUnit = await contract.balanceOf(address);
			const balance = ethers.utils.formatUnits(
				balanceInUnit._hex,
				decimals,
			);
			formik.setFieldValue('balance', Number(balance));
		})();
	}, [formik.values.token]);

	if (!toChainOptions) {
		return (
			<StatusDisplay type={'pending'} label={'Loading chains'} info="" />
		);
	}

	return (
		<form className={styles.root} onSubmit={formik.handleSubmit}>
			<InputSelect
				label="To"
				className={styles.input}
				options={toChainOptions}
				value={formik.values.toChain || ''}
				onChange={(index: string) => {
					const chainConfig = toChainOptions[parseInt(index)].meta;
					formik.setFieldValue('toChain', index);
					formik.setFieldValue('toChainType', chainConfig.chainType);
					formik.values.token && formik.setFieldValue('token', '');
				}}
			/>
			<InputSelect
				label="Token"
				className={clsx('dropdown-style', styles.dropdown, [
					!withdrawFee && styles.margin_bottom,
				])}
				options={tokenOptions ? tokenOptions : []}
				value={formik.values.token || ''}
				disabled={Boolean(!formik.values.toChain)}
				onChange={(index) => {
					formik.setFieldValue('token', index);
				}}
				error={
					formik.touched.token && formik.errors.token
						? formik.errors.token
						: undefined
				}
			/>
			{tokenOptions && formik.values.token && withdrawFee && (
				<p className={styles.fee}>{`Withdrawal fee: ${withdrawFee} ${
					tokenOptions[formik.values.token].label
				}`}</p>
			)}
			<InputText
				label="Recipient's address"
				name="address"
				value={formik.values.address}
				onChange={(value) => {
					formik.setFieldValue('address', value);
				}}
				onBlur={formik.handleBlur}
				error={
					formik.touched.address && formik.errors.address
						? formik.errors.address
						: undefined
				}
				suffix={
					<ScanQrCode
						onChange={(value) => {
							formik.setFieldValue('address', value);
							formik.setTouched({ address: true });
						}}
					>
						<QrScanSVG className={styles.scan} />
					</ScanQrCode>
				}
			/>
			<InputText
				label="Amount"
				name="amount"
				onlyNumbers
				value={formik.values.amount}
				// onChange={(value) => {
				// 	const val = removeLeadingZeroes(value);
				// 	formik.setFieldValue('amount', val);
				// }}
				onChange={(value) => {
					formik.setFieldValue('amount', value);
				}}
				error={
					formik.touched.amount && formik.errors.amount
						? formik.errors.amount
						: undefined
				}
			/>
			<Button
				type="submit"
				className={clsx(
					styles.submitBtn,
					// !isSubmittable && styles.submitBtn_disable,
				)}
				disabled={!formik.values.toChain}
			>
				Submit
			</Button>
		</form>
	);
}

export default WithdrawForm;
