import React, { useContext, useState, useEffect, ReactElement } from 'react';
import axios from 'axios';
import type { ConnectOptions, WalletState } from '@web3-onboard/core';
import { useConnectWallet, useWallets } from '@web3-onboard/react';
import { ethers } from 'ethers';
import IChainConfig from '../constants/interfaces/chainConfig';
import INetworkConfig from '../constants/interfaces/networkConfig';
import IResponseGetNetworks from '../constants/interfaces/response-getNetworks';
import delay from '../utils/delay';

// export type NetworkInfo = {
// 	chainId: number;
// 	// onboardInstance: API;
// 	chainConfig: INetworkConfig;
// };

export type NetworkInfo = INetworkConfig;

interface ContextData {
	data?: any;
}

interface ContextActions {
	action?: any;
}

type Context = [ContextData, ContextActions];

const NetworkContext = React.createContext<Context>([
	// { defaultContract },
	{},
	{},
]);

const autoConnect = async (
	prevConnectedWallets: any,
	connect: (options?: ConnectOptions) => Promise<WalletState[]>,
	onComplete: () => void,
) => {
	await delay(100);
	if (prevConnectedWallets?.length) {
		for (const walletLabel of prevConnectedWallets.reverse()) {
			await connect({
				autoSelect: {
					label: walletLabel,
					disableModals: true,
				},
			});
		}
	}

	onComplete();
};

interface ProviderProps {
	children: React.ReactNode;
}

export const NetworkProvider: React.FC<ProviderProps> = ({ children }) => {
	const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
	const connectedWallets = useWallets();
	const [walletInitialized, setWalletInitialized] = useState(false);

	useEffect(() => {
		const prevConnectedWalletsStr =
			window.localStorage.getItem('connectedWallets');
		const prevConnectedWallets = prevConnectedWalletsStr
			? JSON.parse(prevConnectedWalletsStr)
			: null;

		autoConnect(prevConnectedWallets, connect, () =>
			setWalletInitialized(true),
		);
	}, [connect]);

	useEffect(() => {
		if (!walletInitialized) return;

		const connectedWalletsLabelArray = connectedWallets.map(
			({ label }) => label,
		);
		window.localStorage.setItem(
			'connectedWallets',
			JSON.stringify(connectedWalletsLabelArray),
		);
	}, [connectedWallets, walletInitialized, connect]);

	// useEffect(() => {
	// 	return () => {
	// 		console.log('disconnect')
	// 		disconnect({ label: wallet!.label });
	// 	};
	// }, []);

	return (
		<NetworkContext.Provider value={[{}, {}]}>
			{children}
		</NetworkContext.Provider>
	);
};

export default function useNetwork() {
	return useContext(NetworkContext);
}

export function checkNetwork() {
	const [network, setNetwork] = useState<
		undefined | INetworkConfig | false
	>();
	const [networkList, setNetworkList] = useState<
		undefined | [] | INetworkConfig[]
	>();
	const [{ wallet }] = useConnectWallet();

	useEffect(() => {
		async function updateNetwork(chainId: number) {
			try {
				const result = (
					await axios.get<IResponseGetNetworks>(
						'/secure/bridge/public/networks',
					)
				).data;

				// #NOTE: Disable Polygon for now
				const polygonIdx = result.findIndex(
					(network) => network.chainId === 80001,
				);
				const polygonNetwork =
					polygonIdx >= 0 ? result.splice(polygonIdx, 1) : [];

				setNetworkList(result);

				const networkConfig = result.find(
					(network) => network.chainId == chainId,
				);

				if (!networkConfig) {
					throw {};
				}

				setNetwork(networkConfig);
			} catch (e) {
				setNetwork(() => {
					document
						.querySelector('body > aside[class*="bg-onboard"]')
						?.remove();

					return false;
				});
			}
		}

		// (async () => {
		if (!wallet) return;
		// console.log('wallet.chains', wallet!.chains);
		const chainId = parseInt(wallet!.chains[0].id);
		if (network && network.chainId === chainId) {
			return;
		}
		updateNetwork(chainId);
		// updateNetwork((await web3Provider.getNetwork()).chainId);

		// web3Provider.on('network', (newNetwork) => {
		// 	console.log('newNetwork', newNetwork);
		// 	if (network && network.chainId === newNetwork.chainId) {
		// 		return;
		// 	}

		// 	updateNetwork(newNetwork.chainId);
		// });
		// })();
	}, [wallet]);

	return { network, networkList };
}
