import React, { useContext, useState, useEffect } from 'react';
import { useConnectWallet, useWallets } from '@web3-onboard/react';
import { ethers } from 'ethers';
import styles from '../Bridge/styles.module.scss';

import axios from 'axios';
import { decode, isJwtExpired } from 'utils/token';
import EResponseStatusCode from 'constants/enums/http';
import IResponseGetSignaturePhrase from 'constants/interfaces/response-getSignaturePhrase';
import IResponseConnectWallet from 'constants/interfaces/response-connectWallet';
import StatusDisplay from '../Bridge/views/Online/components/StatusDisplay';
import Button from 'components/Button';
import Branding from 'components/Branding';
import WalletSelect from 'components/WalletSelect';
import WarningModal from 'components/WarningModal';

type Account = {
	id?: string;
	address: string;
	token?: string;
};

interface ContextData {
	data?: any;
	isSignedIn?: boolean;
	account?: Account;
}

interface ContextActions {
	action?: any;
	setIsSignedIn?: any;
	respErrorHandler?: any;
}

type Context = [ContextData, ContextActions];

const ServiceContext = React.createContext<Context>([{}, {}]);

interface ProviderProps {
	children: React.ReactNode;
}

const defaultWarning = {
	code: EResponseStatusCode.ok,
	title: 'Warning',
	content: 'Something went wrong...',
};

const sessionExpiredWarning = {
	code: EResponseStatusCode.unauthorized,
	title: 'Session Expired',
	content: 'Your session has expired. Please log in again.',
};

const serverErrorWarning = {
	code: EResponseStatusCode.server_error,
	title: 'Server Error',
	content: 'Unknown server error.',
};

export const ServiceProvider: React.FC<ProviderProps> = ({ children }) => {
	const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
	const [isSignedIn, setIsSignedIn] = useState(false);
	const [modalContent, setModalContent] = useState(defaultWarning);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [account, setAccount] = useState<Account>();

	const respErrorHandler = (error: any) => {
		if (!error.isResponseError) {
			return;
		}
		switch (error.code) {
			case 401:
				setModalContent(sessionExpiredWarning);
				break;
			case 500:
				setModalContent({
					...serverErrorWarning,
					content: error.message,
				});
				console.log(error.message);
				break;
			default:
				setModalContent({ ...defaultWarning, content: error.message });
				break;
		}
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const closeAndSignout = () => {
		setIsModalOpen(false);
		setIsSignedIn(false);
	};

	// update when user switch account
	useEffect(() => {
		if (!wallet) return;
		// check if current and previous address are not the same
		if (!account || account.address !== wallet!.accounts[0].address) {
			setAccount({
				address: wallet!.accounts[0].address,
			});
			setIsSignedIn(false);
		}
	}, [wallet, account]);

	const fetchAccountData = async () => {
		const currentAddress = wallet!.accounts[0].address;
		const web3Provider = new ethers.providers.Web3Provider(
			wallet!.provider,
		);

		if (
			!account!.token ||
			isJwtExpired(account!.token) ||
			currentAddress !== account!.address
		) {
			try {
				const signer = web3Provider.getSigner();
				const { signaturePhrase } = (
					await axios.get<IResponseGetSignaturePhrase>(
						'/secure/bridge/auth/getSignaturePhrase',
					)
				).data;

				const signature = await signer.signMessage(signaturePhrase);

				const result = (
					await axios.post<IResponseConnectWallet>(
						'/secure/bridge/auth/connectWallet',
						{
							address: wallet!.accounts[0].address,
							signature,
						},
					)
				).data;

				axios.defaults.headers.common[
					'Authorization'
				] = `Bearer ${result.token}`;
				const decodedJwt = decode(result.token);

				setAccount({
					token: result.token,
					...decodedJwt.user,
				});
				setIsSignedIn(true);

				// store to localStorage
				const account = {
					token: result.token,
					...decodedJwt.user,
				};
				// check if some account already exist in localStorage
				const data = window.localStorage.getItem(`${wallet!.label}`);

				if (data) {
					const accounts: Account[] = JSON.parse(data);

					accounts.push(account);

					window.localStorage.setItem(
						`${wallet!.label}`,
						JSON.stringify(accounts),
					);
				} else {
					const accounts: Account[] = [];

					accounts.push(account);

					window.localStorage.setItem(
						`${wallet!.label}`,
						JSON.stringify(accounts),
					);
				}
			} catch (e) {
				if (axios.isAxiosError(e)) {
					console.log('AxiosError', e);
				}
				console.log('error', e);
			}
		}
	};

	useEffect(() => {
		if (!wallet || !account) {
			return;
		}
		if (account.token) {
			return;
		}

		const accountData = loadAccount(
			`${wallet!.label}`,
			wallet!.accounts[0].address,
		);

		if (accountData) {
			axios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${accountData.token}`;

			setAccount(accountData);
			setIsSignedIn(true);
		} else {
			fetchAccountData();
		}
	}, [account, wallet]);

	if (!wallet)
		return (
			<>
				<Branding />
				<WalletSelect connect={connect} />
			</>
		);

	if (!isSignedIn)
		return (
			<>
				<Branding />
				<div className={styles.root}>
					<StatusDisplay
						type={'pending'}
						label={'Waiting for user signature'}
						info=""
					/>
					<Button onClick={fetchAccountData}>Resend</Button>
				</div>
			</>
		);

	return (
		<ServiceContext.Provider
			value={[
				{
					isSignedIn,
					account,
				},
				{
					setIsSignedIn,
					respErrorHandler,
				},
			]}
		>
			<WarningModal
				title={modalContent.title}
				content={modalContent.content}
				isOpen={isModalOpen}
				onClose={
					modalContent.code === EResponseStatusCode.unauthorized
						? closeAndSignout
						: closeModal
				}
				onConfirm={
					modalContent.code === EResponseStatusCode.unauthorized
						? closeAndSignout
						: closeModal
				}
			/>
			{children}
		</ServiceContext.Provider>
	);
};

export default function useService() {
	return useContext(ServiceContext);
}

const loadAccount = (walletLabel: string, address: string) => {
	const data = window.localStorage.getItem(walletLabel);
	if (!data) return null;

	const accounts: Account[] = JSON.parse(data);
	const account = accounts.find((account) => account.address === address);
	if (account) {
		if (isJwtExpired(account.token!)) {
			// remove address from local storage if token is expired and reFetch account data
			const remainAccounts = accounts.filter(
				(account) => account.address !== address,
			);

			window.localStorage.setItem(
				walletLabel,
				JSON.stringify(remainAccounts),
			);

			return null;
		}
	}

	return account;
};
