import Modal from 'react-modal';
import Button from 'components/Button';
import { ReactComponent as ErrorOutlineIcon } from 'assets/icons/error_outline.svg';
import clsx from 'clsx';
import './styles.scss';
import styles from './styles.module.scss';

interface WarningModalProps {
	title: string;
	content: string;
	isOpen: boolean;
	onClose?: () => void;
	onConfirm?: () => void;
}

function WarningModal({
	title,
	content,
	isOpen,
	onClose,
	onConfirm,
}: WarningModalProps) {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="warning-modal"
			contentLabel="network Modal"
		>
			<ErrorOutlineIcon className="warning-modal-icon" />
			<h3>{title}</h3>
			<p>{content}</p>
			{onConfirm && (
				<Button
					type="submit"
					className="warning-modal-button"
					onClick={onConfirm}
				>
					OK
				</Button>
			)}
		</Modal>
	);
}

export default WarningModal;
