import { Fragment } from 'react';
import clsx from 'clsx';
import { ethers } from 'ethers';
import { ReactComponent as BonusSVG } from 'assets/icons/error_outline.svg';
import TruncatedText from 'components/TruncatedText';
import Tooltip from 'components/Tooltip';

type IHistoryRow = {
	action: string;
	amount: string;
	date: string;
	fee?: string;
	from: {
		chainName?: string;
		txHash: string;
		txUrl?: string;
	};
	status: string;
	to: {
		chainName?: string;
		txHash?: string;
		txUrl?: string;
	};
	token: string;
	tag: string;
	decimals: number;
};

export default function Items({
	currentItems,
}: {
	currentItems: IHistoryRow[] | undefined;
}) {
	return (
		<table className="history-table">
			<thead>
				<tr>
					<th className="history-table-title-address">
						Txn Hash
						<span className="history-table-border"></span>
					</th>
					<th className="history-table-title-time">
						Age
						<span className="history-table-border"></span>
					</th>
					<th className="history-table-title-from">
						From Network
						<span className="history-table-border"></span>
					</th>
					<th className="history-table-title-to">
						To Network
						<span className="history-table-border"></span>
					</th>
					<th className="history-table-title-value">
						Value
						<span className="history-table-border"></span>
					</th>
					<th className="history-table-title-token">
						Token
						<span className="history-table-border"></span>
					</th>
					<th className="history-table-title-address">
						Destination Txn Hash
						<span className="history-table-border"></span>
					</th>
					<th className="history-table-title-status">Status</th>
				</tr>
			</thead>
			<tbody>
				{currentItems &&
					currentItems.map((item, index) => {
						const hasPromo = item.tag.includes('promo');
						return (
							<Fragment key={index}>
								<tr className="history-table-topline_tr">
									<td className="history-table-topline_td"></td>
								</tr>
								<tr
									className="history-table-item"
									key={index + 1}
								>
									<td
										className="history-table-item-address"
										data-label="Txn Hash"
									>
										{!hasPromo &&
											(item.from.txUrl ? (
												<a
													href={item.from.txUrl}
													target="_blank"
													rel="noopener noreferrer"
												>
													<TruncatedText
														address={
															item.from.txHash
														}
													/>
												</a>
											) : (
												<TruncatedText
													address={item.from.txHash}
												/>
											))}
									</td>
									<td
										className="history-table-item-time"
										data-label="Age"
									>
										{item.date}
									</td>
									<td
										className="history-table-item-from"
										data-label="From Network"
									>
										{!hasPromo && item.from.chainName}
									</td>
									<td
										className="history-table-item-to"
										data-label="To Network"
									>
										{item.to.chainName}
									</td>
									<td
										className="history-table-item-value"
										data-label="Value"
									>
										{ethers.utils.formatUnits(
											item.amount,
											item.decimals,
										)}
										{hasPromo && (
											<>
												<Tooltip text="BONUS from the USDT deposit">
													<BonusSVG className="history-table-item-value-bonus" />
												</Tooltip>
											</>
										)}
									</td>
									<td
										className="history-table-item-token"
										data-label="Token"
									>
										{item.token}
									</td>
									<td
										className="history-table-item-address"
										data-label="Destination Txn Hash"
									>
										{item.to.txUrl ? (
											<a
												href={item.to.txUrl}
												target="_blank"
												rel="noopener noreferrer"
											>
												<TruncatedText
													address={item.to.txHash}
												/>
											</a>
										) : (
											<TruncatedText
												address={item.to.txHash}
											/>
										)}
									</td>
									<td
										className={clsx(
											'history-table-item-status',
											item.status === 'Pending'
												? 'pending'
												: 'complete',
										)}
										data-label="Status"
									>
										{item.status}
									</td>
								</tr>
							</Fragment>
						);
					})}
			</tbody>
		</table>
	);
}
