import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { formatDistanceToNowStrict } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import ReactPaginate from 'react-paginate';
import useService from 'hooks/useService';
import axios from 'axios';
import IResponseGetChains from 'constants/interfaces/response-getChains';
import IHistoryConfig from 'constants/interfaces/historyConfig';
import './styles.scss';
import { ReactComponent as ArrowLeft } from '../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../assets/icons/arrow-right.svg';
import { ReactComponent as NoHistorySVG } from '../assets/icons/no_found.svg';
import { toTxHash, toTxUrl } from 'utils/url';
import Spinner from 'components/Spinner';
import Items from './components';
import { formatDistance } from 'utils/formatData';

type IHistoryRow = {
	action: string;
	amount: string;
	date: string;
	fee?: string;
	from: {
		chainName?: string;
		txHash: string;
		txUrl?: string;
	};
	status: string;
	to: {
		chainName?: string;
		txHash?: string;
		txUrl?: string;
	};
	token: string;
	tag: string;
	decimals: number;
};

export default function History({
	itemsPerPage = 7,
}: {
	itemsPerPage?: number;
}) {
	const [_, { respErrorHandler }] = useService();

	// We start with an empty list of items.
	const [currentItems, setCurrentItems] = useState<IHistoryRow[] | null>(
		null,
	);
	const [historyData, setHistoryData] = useState<IHistoryRow[] | null>(null);
	const [pageCount, setPageCount] = useState(0);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const controller = new AbortController();

		(async () => {
			try {
				const chains = (
					await axios.get<IResponseGetChains>(
						'/secure/bridge/public/chains',
					)
				).data;

				const data = await axios
					.get<IHistoryConfig[]>('/secure/bridge/txHistory')
					.then((res) => {
						const data = res.data.map((item) => {
							const fromChain = chains.find((chain) => {
								return (
									item.from.chainInfo.chainType ===
										chain.chainType &&
									item.from.chainInfo.chainId ===
										chain.chainId
								);
							});
							const fromChainTxHash = fromChain
								? toTxHash(
										fromChain.chainType,
										item.from.txHash,
								  )
								: item.from.txHash;

							const toChain = chains.find((chain) => {
								return (
									item.to.chainInfo.chainType ===
										chain.chainType &&
									item.to.chainInfo.chainId === chain.chainId
								);
							});
							const toChainTxHash =
								toChain &&
								item.to.txHash &&
								toTxHash(toChain.chainType, item.to.txHash);

							const date = formatDistanceToNowStrict(
								new Date(item.date),
								{
									addSuffix: true,
									locale: {
										...locale,
										formatDistance,
									},
								},
							);
							return {
								action: item.action,
								amount: item.tokenAmount,
								date,
								fee: item.fee,
								from: {
									chainName: fromChain?.name,
									txHash: fromChainTxHash,
									txUrl:
										fromChain &&
										fromChainTxHash &&
										toTxUrl(
											fromChain.chainType,
											fromChain.blockExplorerUrl,
											fromChainTxHash,
										),
								},
								status: item.status,
								to: {
									chainName: toChain?.name,
									txHash: toChainTxHash,
									txUrl:
										toChain &&
										toChainTxHash &&
										toTxUrl(
											toChain.chainType,
											toChain.blockExplorerUrl,
											toChainTxHash,
										),
								},
								token: item.token,
								tag: item.tag,
								decimals: item.decimals,
							};
						});
						return data;
					});
				setHistoryData(data);
			} catch (e) {
				respErrorHandler(e);
			}
		})();
		return () => {
			controller.abort();
		};
	}, []);

	useEffect(() => {
		// Fetch items from another resources.
		const endOffset = itemOffset + itemsPerPage;
		// console.log(`Loading items from ${itemOffset} to ${endOffset}`);
		if (historyData) {
			setPageCount(Math.ceil(historyData.length / itemsPerPage));
			setCurrentItems(historyData.slice(itemOffset, endOffset));
		}
	}, [historyData, itemOffset, itemsPerPage]);

	useEffect(() => {
		currentItems ? setLoading(false) : setLoading(true);
	}, [currentItems]);

	// Invoke when user click to request another page.
	const handlePageClick = (event: { selected: number }) => {
		if (historyData) {
			const newOffset =
				(event.selected * itemsPerPage) % historyData.length;
			setItemOffset(newOffset);
		}
	};
	const hasData = currentItems && currentItems.length > 0;
	return (
		<div className={clsx(hasData ? 'history' : 'no_history')}>
			{loading ? (
				<Spinner />
			) : hasData ? (
				<>
					<div className="history-table-line"></div>
					<Items currentItems={currentItems} />
					<ReactPaginate
						nextLabel={<ArrowRight />}
						onPageChange={handlePageClick}
						pageRangeDisplayed={7}
						marginPagesDisplayed={0}
						pageCount={pageCount}
						previousLabel={<ArrowLeft />}
						pageClassName="page-item"
						pageLinkClassName="page-link"
						previousClassName="page-item"
						previousLinkClassName="page-link"
						nextClassName="page-item"
						nextLinkClassName="page-link"
						breakLabel="..."
						breakClassName="page-item"
						breakLinkClassName="page-link"
						containerClassName="pagination"
						activeClassName="active"
						renderOnZeroPageCount={undefined}
					/>
				</>
			) : (
				<>
					<NoHistorySVG />
					<p>No transactions found</p>
				</>
			)}
		</div>
	);
}
