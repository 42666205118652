import { formDataRef } from './../../index';
import { ethers } from 'ethers';
import { TransferFormProps } from '../..';

type IArgs = TransferFormProps;

export async function submitAction({ onSubmit, onError, onSend }: IArgs) {
	// const { amount, contract: _contract } = formDataRef.current;
	const { address, token, amount, contract: _contract } = formDataRef.current;

	onSubmit();

	try {
		if (!_contract) {
			throw Error();
		}

		const value = ethers.utils.parseUnits(amount, token!.decimals)._hex;

		const result = await _contract.transfer(address, value);

		result.hash && onSend(result.hash);
	} catch (e) {
		console.log('submit error', e);
		onError();
	}
}
